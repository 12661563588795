import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from '@sbd-doug-frontend/app/core/auth/auth.utils';
import { UserService } from '@sbd-doug-frontend/app/core/user/user.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService {
  private _authenticated = false;
  private _xbadminCipx: string | null = null;
  private _xbuserCipx: string | null = null;
  private _sessionId: string | null = null;
  /**
   * Constructor
   */
  constructor(
    private _cookieService: CookieService,
    private _httpClient: HttpClient,
    private _userService: UserService
  ) {
    this._xbadminCipx = this._cookieService.get('xbadmin_cipx');
    this._xbuserCipx = this._cookieService.get('xbauser_cipx');
    this._sessionId = this._cookieService.get('PHPSESSID');

    // this._authenticated = this._xbadminCipx != null && this._xbuserCipx != null && this._sessionId != null &&
    //   this._xbadminCipx.length > 0 && this._xbadminCipx.length > 0 && this._sessionId.length > 0;

    this._authenticated = this._xbadminCipx != null && this._sessionId != null &&
      this._xbadminCipx.length > 0 && this._xbadminCipx.length > 0 && this._sessionId.length > 0;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post('api/auth/sign-in', credentials).pipe(
      switchMap((response: any) => {

        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient.post('api/auth/sign-in-with-token', {
      accessToken: this.accessToken
    }).pipe(
      catchError(() =>

        // Return false
        of(false)
      ),
      switchMap((response: any) => {

        // Replace the access token with the new one if it's available on
        // the response object.
        //
        // This is an added optional step for better security. Once you sign
        // in using the token, you should generate a new one on the server
        // side and attach it to the response object. Then the following
        // piece of code can replace the token with the refreshed one.
        if (response.accessToken) {
          this.accessToken = response.accessToken;
        }

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return true
        return of(true);
      })
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    this._cookieService.deleteAll();

    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // // Check the access token availability
    // if ( !this.accessToken )
    // {
    //     return of(false);
    // }

    // // Check the access token expire date
    // if ( AuthUtils.isTokenExpired(this.accessToken) )
    // {
    //     return of(false);
    // }

    // // If the access token exists and it didn't expire, sign in using it
    // return this.signInUsingToken();
    return of(false);
  }
}
